import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/_renderRoutes';
import _dvaDynamic from 'dva/dynamic'

let Router = require('dva/router').routerRedux.ConnectedRouter;

let routes = [
  {
    "path": "/",
    "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__index" */'../../layouts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
}),
    "routes": [
      {
        "path": "/",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__index" */'../index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__index" */'../index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/chart/ECharts",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__chart__ECharts__index" */'../chart/ECharts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/chart/ECharts",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__chart__ECharts__index" */'../chart/ECharts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/chart/Recharts",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__chart__Recharts__index" */'../chart/Recharts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/chart/Recharts",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__chart__Recharts__index" */'../chart/Recharts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/chart/highCharts",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__chart__highCharts__index" */'../chart/highCharts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/chart/highCharts",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__chart__highCharts__index" */'../chart/highCharts/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/cms",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__index" */'../cms/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/cms",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__index" */'../cms/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/cms/curatorData",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__curatorData" */'../cms/curatorData.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/cms/curatorData",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__curatorData" */'../cms/curatorData.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/cms/curators",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__curators" */'../cms/curators.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/cms/curators",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__curators" */'../cms/curators.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/cms/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__dashboard" */'../cms/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/cms/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__cms__dashboard" */'../cms/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__dashboard__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/dashboard/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__dashboard__index" */'../dashboard/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__dashboard__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/dashboard/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__dashboard__index" */'../dashboard/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong/titles",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__titles" */'../zong/titles.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong/titles",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__titles" */'../zong/titles.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong/programs",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__programs" */'../zong/programs.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong/programs",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__programs" */'../zong/programs.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/404",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__404" */'../404.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/404",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__404" */'../404.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/live",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__index" */'../live/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/live",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__index" */'../live/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/live/Ads",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__Ads" */'../live/Ads.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/live/Ads",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__Ads" */'../live/Ads.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/live/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__dashboard" */'../live/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/live/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__dashboard" */'../live/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/live/data",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__data" */'../live/data.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/live/data",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__data" */'../live/data.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/live/hourlyGraph",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__hourlyGraph" */'../live/hourlyGraph.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/live/hourlyGraph",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__hourlyGraph" */'../live/hourlyGraph.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/live/msisdnWise",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__msisdnWise" */'../live/msisdnWise.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/live/msisdnWise",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__live__msisdnWise" */'../live/msisdnWise.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/login",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__login__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/login/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__login__index" */'../login/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/login",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__login__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/login/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__login__index" */'../login/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/request",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__request__index" */'../request/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/request",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__request__index" */'../request/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/UIElement/editor",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__UIElement__editor__index" */'../UIElement/editor/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/UIElement/editor",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__UIElement__editor__index" */'../UIElement/editor/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/user",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__user__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/user/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__user__index" */'../user/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/user",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__user__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/user/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__user__index" */'../user/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/user/:id",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__user__$id__models__detail.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/user/$id/models/detail.js').then(m => { return { namespace: 'detail',...m.default}}),
  import(/* webpackChunkName: 'p__user__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/user/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__user__$id__index" */'../user/$id/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/user/:id",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__user__$id__models__detail.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/user/$id/models/detail.js').then(m => { return { namespace: 'detail',...m.default}}),
  import(/* webpackChunkName: 'p__user__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/user/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__user__$id__index" */'../user/$id/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/PaywallReports/TabOne/TabOne",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__TabOne__TabOne" */'../PaywallReports/TabOne/TabOne.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/PaywallReports/TabOne/TabOne",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__TabOne__TabOne" */'../PaywallReports/TabOne/TabOne.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/PaywallReports/TabOne/TableComponent",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__TabOne__TableComponent__index" */'../PaywallReports/TabOne/TableComponent/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/PaywallReports/TabOne/TableComponent",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__TabOne__TableComponent__index" */'../PaywallReports/TabOne/TableComponent/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/PaywallReports/TabOne/TableComponent/TabComponentTwo",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__TabOne__TableComponent__TabComponentTwo" */'../PaywallReports/TabOne/TableComponent/TabComponentTwo.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/PaywallReports/TabOne/TableComponent/TabComponentTwo",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__TabOne__TableComponent__TabComponentTwo" */'../PaywallReports/TabOne/TableComponent/TabComponentTwo.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/PaywallReports",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__index" */'../PaywallReports/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/PaywallReports",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__PaywallReports__index" */'../PaywallReports/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__index" */'../vod/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__index" */'../vod/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/ads",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__ads" */'../vod/ads.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/ads",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__ads" */'../vod/ads.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/anchor",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__anchor" */'../vod/anchor.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/anchor",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__anchor" */'../vod/anchor.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/categories",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__categories" */'../vod/categories.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/categories",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__categories" */'../vod/categories.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/channel",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__channel" */'../vod/channel.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/channel",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__channel" */'../vod/channel.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__dashboard" */'../vod/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__dashboard" */'../vod/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/msisdnWise",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__msisdnWise" */'../vod/msisdnWise.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/msisdnWise",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__msisdnWise" */'../vod/msisdnWise.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/programs",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__programs" */'../vod/programs.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/programs",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__programs" */'../vod/programs.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/vod/titles",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__titles" */'../vod/titles.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/vod/titles",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__vod__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/vod/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__vod__titles" */'../vod/titles.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong/anchor",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__anchor" */'../zong/anchor.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong/anchor",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__anchor" */'../zong/anchor.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong/categories",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__categories" */'../zong/categories.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong/categories",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__categories" */'../zong/categories.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__index" */'../zong/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__index" */'../zong/index.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__dashboard" */'../zong/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong/dashboard",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__dashboard" */'../zong/dashboard.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/zong/live",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__live" */'../zong/live.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/zong/live",
        "exact": true,
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__zong__model.js' */'/home/centos/prod/goonj_reporting_frontend_revamp/src/pages/zong/model.js').then(m => { return { namespace: 'model',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__zong__live" */'../zong/live.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/adManager",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__adManager" */'../adManager.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/adManager",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__adManager" */'../adManager.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/excel",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__excel" */'../excel.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/excel",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__excel" */'../excel.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/googleAnalytics",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__googleAnalytics" */'../googleAnalytics.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "path": "/:lang(en)/googleAnalytics",
        "exact": true,
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__googleAnalytics" */'../googleAnalytics.js'),
  LoadingComponent: require('/home/centos/prod/goonj_reporting_frontend_revamp/src/components/Loader/Loader').default,
})
      },
      {
        "component": () => React.createElement(require('/home/centos/prod/goonj_reporting_frontend_revamp/node_modules/umi-build-dev/lib/plugins/404/NotFound.js').default, { pagesPath: 'src/pages', hasRoutesInConfig: false })
      }
    ]
  },
  {
    "component": () => React.createElement(require('/home/centos/prod/goonj_reporting_frontend_revamp/node_modules/umi-build-dev/lib/plugins/404/NotFound.js').default, { pagesPath: 'src/pages', hasRoutesInConfig: false })
  }
];
window.g_routes = routes;
window.g_plugins.applyForEach('patchRoutes', { initialValue: routes });

// route change handler
function routeChangeHandler(location, action) {
  window.g_plugins.applyForEach('onRouteChange', {
    initialValue: {
      routes,
      location,
      action,
    },
  });
}
window.g_history.listen(routeChangeHandler);
routeChangeHandler(window.g_history.location);

export default function RouterWrapper() {
  return (
<Router history={window.g_history}>
      { renderRoutes(routes, {}) }
    </Router>
  );
}
